import {collection, getDocs, orderBy, query} from "firebase/firestore";
import { db } from "../../utils/config";
import SubMenu from "./SubMenu";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";


const Consultores = ({title}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const sociosCollection = collection(db, "nosotros-consultores");
        const getSocios = async () => {
            const q = query(sociosCollection, orderBy("orden", "asc"))
            const data = await getDocs(q);
            setData(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }
        getSocios();
    }, []);
    const [modal, setModal] = useState ({
        
        data: data

    });
    const mostrarModal = (consultores) => {
        setModal({
            data: consultores
        });
    };
    document.title = title+" | AFS - Arquitectos"

    return(
        <div className="container padd-global">
            <SubMenu />
            <div className="sociosImg row">
                {
                    data.map(consultores =>(
                        <div className="col-md-4 col-sm-6 col-12" key={consultores.id}>
                            <div className="boxConsultores" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" onClick={() => mostrarModal(consultores)}>
                                <img loading="lazy" src={consultores.imagen} className="w-100" alt=""  />
                            </div>
                        </div>
                    ))
                }
                
            </div>
            <div className="col-12 text-end"><Link to='/nosotros/' className='btnVolverAtras'><i className="fa-solid fa-arrow-left"></i> Volver a Nosotros</Link></div>
            <hr className="mb-0" />
            <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modalSociosOpen modal-body row">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="col-md-5">
                                <img src={modal.data.imagen} className="w-100" alt="" />
                            </div>
                            <div className="col-md-6 consultoresLista" dangerouslySetInnerHTML={{ __html: modal.data.consultores }}>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Consultores;