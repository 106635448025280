import {collection, getDocs, orderBy, query} from "firebase/firestore";
import { db } from "../../utils/config";
import SubMenu from "./SubMenu";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const Socios = ({title}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const sociosCollection = collection(db, "nosotros-socios");
        const getSocios = async () => {
            const q = query(sociosCollection, orderBy("orden", "asc"))
            const data = await getDocs(q);
            setData(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }
        getSocios();
    }, []);
    const [modal, setModal] = useState ({
        
        data: data

    });
    const mostrarModal = (socio) => {
        setModal({
            data: socio
        });
    };
    document.title = title+" | AFS - Arquitectos"
    return(
        <div className="container padd-global">
            <SubMenu />
            <div className="sociosImg row">
                {
                    data.map(socio => (
                    <div className="col-md-6 col-sm-6 col-12" key={socio.id} onClick={() => mostrarModal(socio)}>
                        <div className="SociosBoxImg" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">
                            <img loading="lazy" src={socio.imagen} className="w-100" alt=""  />
                            <h3 className="m-0">{socio.nombre}</h3>
                            <p className="m-0">{socio.cargo}</p>
                        </div>
                    </div>
                    ))
                }
            </div>
            <div className="col-12 text-end"><Link to='/nosotros/' className='btnVolverAtras'><i className="fa-solid fa-arrow-left"></i> Volver a Nosotros</Link></div>
            <hr className='mb-0' />
            <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modalSociosOpen modal-body row">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="col-md-7">
                                <h2 className="m-0">{modal.data.nombre}</h2>
                                <p className="subttSocios">{modal.data.cargo}</p>
                                <div className="sociosModalDescripcion" dangerouslySetInnerHTML={{ __html: modal.data.biografia }}>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <img loading="lazy" src={modal.data.imagen} className="w-100" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Socios;