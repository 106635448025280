import {collection, getDocs, orderBy, query} from "firebase/firestore";
import { db } from "../../utils/config";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './proyectos.css';

const Proyectos = () => {
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState({});

    useEffect(() => {
        const sociosCollection = collection(db, "proyectos");
        const getProyectos = async () => {
            const q = query(sociosCollection, orderBy("orden", "asc"))
            const data = await getDocs(q);
            setData1(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }
        getProyectos();
    }, []);
    useEffect(() => {
        const sociosCollection = collection(db, "lista-proyectos");
        const getListaProyectos = async () => {
            const data = await getDocs(sociosCollection);
            const datosLista = data.docs.map((doc) => ({...doc.data()}))
            setData2({...datosLista[0]});
        }
        getListaProyectos();
    }, []);
    document.title = "Proyectos | AFS - Arquitectos"
    return(
        <>
            <div className="container padd-global">
                <div className="imgGaleriaProyectos row">
                    {
                        data1.map(proyecto => (
                            <div className='col-md-4 col-sm-6 col-12' key={proyecto.id}>
                                <Link to={'/proyectos/'+proyecto.id}>
                                    <div className="boxProyectos">
                                        <img loading="lazy" alt="" className="w-100" src={proyecto.portada} />
                                        <div className="degradeBackground"></div>
                                        <div className="boxTextProyectos">
                                            <h2 className='m-0'>{proyecto.titulo}</h2>
                                            <p className='m-0'>{proyecto.ubicacion}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            ))
                    }
                </div>
                <div className="box-lista-trabajos overflow-auto" dangerouslySetInnerHTML={{ __html: data2.listas }}>
                        
                </div>
                <hr />
            </div>
        </>
    );
}
export default Proyectos