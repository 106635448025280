import {collection, getDocs, orderBy, query} from "firebase/firestore";
import { db } from "../../utils/config";
import SubMenu from "./SubMenu";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const Colaboradores = ({title}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const sociosCollection = collection(db, "nosotros-colaboradores");
        const getSocios = async () => {
            const q = query(sociosCollection, orderBy("orden", "asc"))
            const data = await getDocs(q);
            setData(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }
        getSocios();
    }, []);

    document.title = title+" | AFS - Arquitectos"
    return (
        <div className="container padd-global">
            <SubMenu />
            <div className="sociosImg row">
                {
                    data.filter(item => item.categoria === 'colaboradores').map(colaborador => (
                        <div className="col-md-4 col-sm-6 col-12" key={colaborador.id}>
                            <div className="SociosBoxImg">
                                <img src={colaborador.imagen} className="w-100" alt=""  />
                                <h3 className="m-0">{colaborador.nombre}</h3>
                                <p className="m-0" dangerouslySetInnerHTML={{ __html: colaborador.cargo }}></p>
                            </div>
                        </div>
                    ))
                }
                
            </div>
            <div className="sociosImg row">
                <h2 className="subSubColaboradores">Asociados</h2>
                {
                    data.filter(item => item.categoria === 'asociados').map(asociados => (
                        <div className="col-md-4 col-sm-6 col-12" key={asociados.id}>
                            <div className="SociosBoxImg">
                                <img loading="lazy" src={asociados.imagen} className="w-100" alt=""  />
                                <h3 className="m-0">{asociados.nombre}</h3>
                                <p className="m-0" dangerouslySetInnerHTML={{ __html: asociados.cargo }}></p>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="col-12 text-end"><Link to='/nosotros/' className='btnVolverAtras'><i className="fa-solid fa-arrow-left"></i> Volver a Nosotros</Link></div>
            <hr className="mb-0" />
        </div>
    );
}
export default Colaboradores;