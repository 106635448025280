import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../utils/img/logo.png';
import './NavBar.css';
const NavBar = () => {
    const [header, setHeader] = useState(false);
    const [mobile, setMobile] = useState(false)
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
        document.getElementById("navPrincipal").style.top = "0";
        setHeader(true);
        
    } else {
        document.getElementById("navPrincipal").style.top = "-100px";
        setMobile(false)
    }
    prevScrollpos = currentScrollPos;
    if(currentScrollPos === 0){
        setHeader(false);
    }
    }
    const handleNavBar = (valor) => {
        setMobile(valor);
    }
    return(
        <>
            <header id='navPrincipal' className={header ? 'headerFlotante' : ''}>
                <div className="container">
                    <div className="flexbox">
                        <div className="logo">
                            <Link to='/'><img src={logo} alt="AFS Arquitectos" width="220" /></Link>
                        </div>
                        <nav>
                            <span className='navBarMobile'>
                                {
                                    mobile ? <i className="fa-solid fa-x" onClick={()=> handleNavBar(false)}></i> : <i className="fa-solid fa-bars" onClick={()=> handleNavBar(true)}></i>
                                }
                            </span>
                            <ul className={mobile ? 'listMobile' : ''}>
                                <li><NavLink to='/nosotros' className={({isActive}) => (isActive ? "active" : '')} onClick={()=> handleNavBar(false)}>Nosotros</NavLink></li>
                                <li><NavLink to='/proyectos' className={({isActive}) => (isActive ? "active" : '')} onClick={()=> handleNavBar(false)}>Proyectos</NavLink></li>
                                <li><NavLink to='/novedades' className={({isActive}) => (isActive ? "active" : '')} onClick={()=> handleNavBar(false)}>Novedades</NavLink></li>
                                <li><NavLink to='/contacto' className={({isActive}) => (isActive ? "active" : '')} onClick={()=> handleNavBar(false)}>Contacto</NavLink></li>
                                <li><a href="https://www.linkedin.com/company/afs-arquitectos/" target="_blank" rel="noopener noreferrer" onClick={()=> handleNavBar(false)}><i className="fab fa-linkedin"></i></a></li>
                            </ul>
                            
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )
}
export default NavBar