import {collection, getDocs, orderBy, query} from "firebase/firestore";
import { db } from "../../utils/config";
import { useState, useEffect } from 'react';
import './Novedades.css';
import { Link } from "react-router-dom";

const Novedades = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const sociosCollection = collection(db, "novedades");
        const getSocios = async () => {
            const q = query(sociosCollection, orderBy("fecha", "desc"))
            const data = await getDocs(q);
            setData(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }
        getSocios();
    }, []);
    document.title = "Novedades | AFS - Arquitectos"
    return (
        <>
            <div className="container padd-global">
                {
                    data.map(item =>(
                        <Link to={'/novedades/'+item.id} key={item.id}>
                            <div className="boxNovedades row">
                                <div className="col-md-8 col-12">
                                    <h2>{item.titulo}</h2>
                                    <div className="boxNovedadesText" dangerouslySetInnerHTML={{ __html: item.descripcion}}></div>
                                    <p className="leerMas">Leer Más</p>
                                </div>
                                <div className="col-md-4 col-12"> 
                                    <img loading="lazy" src={item.imagenes[0]} alt="" className="w-100" />
                                </div>
                            </div>
                        </Link>
                    ))
                }
                <hr />
            </div>
        </>
    );
}
export default Novedades;