import { NavLink } from "react-router-dom";

const SubMenu = () => {
    return(
        <>
        <div className="subMenuCont">
            <ul className='subMenuNosotros'>
                <li><NavLink to='/nosotros/socios' className={({isActive}) => (isActive ? "active" : '')}>Socios</NavLink></li>
                <li><NavLink to='/nosotros/colaboradores' className={({isActive}) => (isActive ? "active" : '')}>Colaboradores - Asociados</NavLink></li>
                <li><NavLink to='/nosotros/consultores' className={({isActive}) => (isActive ? "active" : '')}>Consultores</NavLink></li>
                <li><NavLink to='/nosotros/competencia' className={({isActive}) => (isActive ? "active" : '')}>Competencia</NavLink></li>
            </ul>
        </div>
        </>
    );
}
export default SubMenu;