import './Contacto.css';
const Contacto = () => {
    document.title = "Contacto | AFS - Arquitectos"

    return(
        <>
            <div className="container padd-global">
            <iframe src="https://www.google.com/maps/embed?pb=!4v1675986536786!6m8!1m7!1sNYUCtdbeh8fZbZLQlsldog!2m2!1d-34.58926825633939!2d-58.40195904386048!3f97.72259915882017!4f7.535064648152257!5f0.7820865974627469" title={'Mapa'}></iframe>
                <div className="infoContacto">
                    <p className='m-0'>French 2699 2° piso (C1425AWC)</p>
                    <p className='m-0'>Ciudad de Buenos Aires - Argentina</p>
                    <a href='mailto:info@afs-arq.com.ar'>info@afs-arq.com.ar</a>
                    <p className='m-0'>Tel: +54 11 4805-7395 / 4807-5485</p>
                    <br />
                    <a href='mailto:info@afs-arq.com.ar'><button type="button" className="btn btn-secondary ContactoEmail">Email</button></a>
                    <a href="https://www.google.com/maps/place/French+2699,+C1425AWC+CABA/@-34.5892885,-58.4032224,17z/data=!4m6!3m5!1s0x95bcca9b949a0115:0x2705d3e4b3af6c2a!8m2!3d-34.5892737!4d-58.4017138!16s%2Fg%2F11hzxqhzv9" target="_blank" rel="noopener noreferrer"><button type="button" className="btn btn-secondary">Ver Mapa</button></a>
                </div>
                <hr className='mb-0' />
            </div>
        </>
    );
}
export default Contacto