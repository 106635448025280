import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
    document.title = "AFS - Arquitectos"

    return(
        <>
        <div className="container-home-fluid">
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="8" aria-label="Slide 6"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src='https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Slider%20Home%2F01-S%20Mas%20de%2050.jpg?alt=media&token=ceb93cae-5a16-4b46-8106-86c1f0b737bd' className="d-block w-100" alt="..." />
                        <div className="carousel-caption">
                            <h5>Mas de 50 Años de <br /> Emprendimientos para la Salud</h5>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src='https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Slider%20Home%2F02-S-Aporte-a-la-Comunidad.jpg?alt=media&token=78401a39-4b9e-4bcc-b4b7-58d8f32e524f' className="d-block w-100" alt="..." />
                        <div className="carousel-caption">
                            <h5>Aporte a la Comunidad</h5>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src='https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Slider%20Home%2F03-S%20Respeto%20por%20el%20Medio%20Ambiente.jpg?alt=media&token=7141ba2f-1bc2-4d40-9aa9-cdd510650942' className="d-block w-100" alt="..." />
                        <div className="carousel-caption">
                            <h5>Respeto por el Medio Ambiente</h5>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src='https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Slider%20Home%2F04-S%20Humanizacion%20del%20Lenguaje.jpg?alt=media&token=840436f4-72c3-4263-b9ec-05b964bdd6d5' className="d-block w-100" alt="..." />
                        <div className="carousel-caption">
                            <h5>Humanización del Lenguaje</h5>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src='https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Slider%20Home%2F05-S%20Respeto%20por%20el%20Contexto%20Urbano.jpg?alt=media&token=030781b9-d326-4f66-baad-3806469f2e94' className="d-block w-100" alt="..." />
                        <div className="carousel-caption">
                            <h5>Respeto por el Contexto Urbano</h5>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src='https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Slider%20Home%2F06-S%20Accesibilidad.jpg?alt=media&token=aea0e115-b779-45ff-bcdd-dc62eabde3f9' className="d-block w-100" alt="..." />
                        <div className="carousel-caption">
                            <h5>Accesibilidad</h5>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img loading="lazy" src='https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Slider%20Home%2F07-S%20Paisaje%20y%20Luz%20Natural.jpg?alt=media&token=a06b6212-7016-45c8-b192-d4c51be8854b' className="d-block w-100" alt="..." />
                        <div className="carousel-caption">
                            <h5>Paisaje y Luz Natural</h5>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img loading="lazy" src='https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Slider%20Home%2F08-S%20Innovacion%20Tecnologica.jpg?alt=media&token=d9850b88-9928-4411-ad3d-243376c9e40c' className="d-block w-100" alt="..." />
                        <div className="carousel-caption">
                            <h5>Innovación Tecnológica</h5>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img loading="lazy" src='https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Slider%20Home%2F09-S%20Soluciones%20Sustentables.jpg?alt=media&token=9bd0c1f8-f999-4dae-a408-51e75c266955' className="d-block w-100" alt="..." />
                        <div className="carousel-caption">
                            <h5>Soluciones Sustentables</h5>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className="secundarioHome">
                <div className="flexbox">
                    <Link to='/nosotros' className="box" style={{backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Botones%20Home%2FNosotros.jpg?alt=media&token=a0ba8196-f164-4289-b013-a226c4fb5ae2')`, backgroundSize: 'cover', backgroundPosition: 'center',backgroundRepeat: 'no-repeat'}}>
                        <div className="boxFondo"></div>
                        <p>Nosotros</p>
                    </Link>
                    <Link to='/proyectos' className="box" style={{backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Botones%20Home%2FProyectos.jpg?alt=media&token=396921f6-612a-4ab9-b603-d0af219ebd97')`, backgroundSize: 'cover', backgroundPosition: 'center',backgroundRepeat: 'no-repeat'}}>
                        <div className="boxFondo"></div>
                        <p>Proyectos</p> 
                        </Link>
                    <Link to='/novedades' className="box" style={{backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Botones%20Home%2FNovedades.jpg?alt=media&token=b5b83438-f2f1-4cf0-a956-592d51965c3e')`, backgroundSize: 'cover', backgroundPosition: 'center',backgroundRepeat: 'no-repeat'}}>
                        <div className="boxFondo"></div>
                        <p>Novedades</p>
                    </Link>
                </div>
            </div>
        </div>
        </>
    );
}
export default Home;