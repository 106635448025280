import { Link, useParams } from 'react-router-dom';
import {getDoc, doc} from "firebase/firestore";
import { db } from "../../utils/config";
import { useState, useEffect } from 'react';
import Loader from '../Loader';
import Error404 from '../../error-404';

const Proyecto = () => {

    const [dato, setDatos] = useState({});
    const [existe, setExiste] = useState(false);
    const [loader, setloader] = useState(true)
    const { id } = useParams();
    const getSocios = async (id) => {
        const novedad = await getDoc(doc(db, "proyectos", id))
        if(novedad.exists()){
            setDatos(novedad.data())
            setExiste(true)
            setloader(false)
        }else {
            setExiste(false)
            setloader(false)
        }
    }
    useEffect(() => {
        getSocios(id);
    }, [id]);
    document.title = dato.titulo+" | AFS - Arquitectos"
    return(
        <>
            {loader && <Loader />}
            {
            existe ?
                <div className="container padd-global">
                    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
                        <div className="carousel-indicators">
                            {
                                dato.slider.map((list, i) => (
                                    <button key={i} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={i} className={i === 0 ? "active" : "" }></button>
                                ))
                            }
                        </div>
                        <div className="carousel-inner">
                            {
                                dato.slider.map((img, index) => (
                                    <div className={index === 0 ? "carousel-item active" : "carousel-item" } key={index}>
                                        <img src={img} className="d-block w-100" alt="..." />
                                    </div>
                                ))
                            }
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    <div className="campoTextoProyecto">
                        <h1>{dato.titulo}</h1>
                        <h2>{dato.lugar}</h2>
                        <div dangerouslySetInnerHTML={{ __html: dato.descripcion }}></div>
                        <div className="col-12 text-end"><Link to='/proyectos/' className='btnVolverAtras'><i className="fa-solid fa-arrow-left"></i> Volver a Proyectos</Link></div>
                    </div>
                    <hr className='mt-5' />
                </div>
            : <Error404 title={'Erro 404'} />
            }
        </>
    );
}
export default Proyecto;