import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return(
        <>
        <footer>
            <div className="container-fluid">
                <p className='m-0'>French 2699 2° piso (C1425AWC) Ciudad de Buenos Aires - Argentina</p>  
                <p className='m-0'>info@afs-arq.com.ar | Tel: +54 11 4805-7395 / 4807-5485</p>
                <div className="text-center">
                    <a href="https://talenton.com.ar/" target="_blank" rel="noopener noreferrer"><img src="https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/variado%2FDiseno-y-Desarrollo-Talenton.png?alt=media&token=c64a1dbd-b868-43c1-b12a-9cf0eb83faed" alt="Talenton" width="100" className='mt-4' /></a>
                </div>
            </div>
        </footer>
        </>
    )
}
export default Footer;