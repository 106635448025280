import './Nosotros.css';
import SubMenu from './SubMenu';

const Nosotros = () => {
        document.title = "Nosotros | AFS - Arquitectos"
    return(
        <>
        <div className="container padd-global">
            <SubMenu />
            <p className='parrafo'>Con sede en Buenos Aires desde el año 1966, el estudio AFS Arquitectos ha trabajado ininterrumpidamente integrando un equipo de capacitados profesionales entrenados en el trabajo interdisciplinario y participativo. <br/> AFS Arquitectos se ha especializado en emprendimientos para la Salud, públicos, privados y de obras sociales, desde su programación y diseño hasta su puesta en marcha. El número de proyectos y edificios realizados, así como la asistencia técnica brindada a sus comitentes lo ubican entre los estudios líderes en la especialidad.</p>
            <img src="https://firebasestorage.googleapis.com/v0/b/afs-arquitectos.appspot.com/o/Nosotros%2FStaff-Portada-r.jpg?alt=media&token=7fe39eeb-7ffe-4fc3-a64b-551cc969f9e7" alt="" className='w-100' />
            <p className="parrafo">AFS Arquitectos mantiene un diálogo constante con instituciones y colegas de nuestro medio y del exterior: UBA, IFHE, AADAIH, entes regulatorios y áreas de desarrollo del ámbito empresarial, procurando la actualización tecnológica en terrenos como el de la sustentabilidad, diseño bio-climático, diseño humanizado centrado en el paciente, eficiencia energética y gestión del recurso físico.</p>
            <hr className='mb-0' />
        </div>
        </>
    );
}
export default Nosotros;