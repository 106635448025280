import { Link } from "react-router-dom";
import SubMenu from "./SubMenu";

const Competencia = ({title}) => {

    document.title = title+" | AFS - Arquitectos"

    return(
        <div className="container padd-global">
            <SubMenu />
            <div className="competenciaBox">
                <div className="row">
                    <div className="col-md-6">
                        <ul className="listaCompetencia">
                            <li>PLANIFICACIÓN ESTRATÉGICA</li>
                            <li>ESTUDIO DE FACTIBILIDAD</li>
                            <li>PROGRAMACIÓN FÍSICO FUNCIONAL</li>
                            <li>PLAN MAESTRO</li>
                            <li>DISEÑO INTEGRAL DEL RECURSO FÍSICO EN SALUD, EQUIPAMIENTO E INSTALACIONES</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul className="listaCompetencia">
                            <li>DISEÑO BIOAMBIENTAL, EFICIENCIA ENERGÉTICA Y SUSTENTABILIDAD</li>
                            <li>DISEÑO DE INTERIORES, ILUMINACIÓN, COLOR, MOBILIARIO</li>
                            <li>DISEÑO DE IMAGEN CORPORATIVA</li>
                            <li>DIRECCIÓN TÉCNICA DE OBRA</li>
                            <li>GESTIÓN DE PROYECTO, CONSTRUCCIÓN Y PUESTA EN MARCHA</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-12 text-end"><Link to='/nosotros/' className='btnVolverAtras'><i className="fa-solid fa-arrow-left"></i> Volver a Nosotros</Link></div>
            <hr className="mb-0" />
        </div>
    );
}
export default Competencia;